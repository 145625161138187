import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Portfolio from './components/Portfolio';
import Timeline from './components/Timeline';
import LinkTree from './components/LinkTree';
import Knowledge from './components/Knowledge';
import NotFound from './components/NotFound';
import Contact from './components/Contact';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import Navody from './components/Navody';
import NavodyPost from './components/NavodyPost';
import Playing from './components/Playing';
import Equipment from './components/Equipment';
import Tech from './components/Tech';
// Ratings
import Linux from './components/ratings/Linux';
import Aliexpress from './components/ratings/Aliexpress';
import AliexTech from './components/ratings/AliexTech';
// ...
import './styles.css';
import { FaDiscord, FaInstagram, FaLinkedin, FaBars, FaTimes } from 'react-icons/fa';

const App: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const links = [
        { title: 'LinkedIn', url: 'https://linkedin.com/in/ondrejpacovsky/', icon: './assets/links/linkedin.png' },
        { title: 'Instagram', url: 'https://instagram.com/ondrasekq_/', icon: './assets/links/instagram.png' },
        { title: 'Discord', url: 'https://discord.com/users/570547780804804611', icon: './assets/links/discord.svg' },
        { title: 'Gameflip', url: 'https://gameflip.com/profile/us-east-1:629afc31-aa3b-45b3-aff7-f50b18864c5b/ondra907', icon: './assets/links/gameflip.webp' },
        { title: 'Halmine', url: 'https://halmine.cz/', icon: './assets/links/halmine.png' },
    ];

    return (
        <Router>
            <div className="app-container">
                <nav className="navbar">
                    <Link to="/">
                    <div className="navbar-brand">
                        <img src="/assets/logo.png" alt="Logo" className="navbar-logo" />
                    </div>
                    </Link>
                    <ul className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
                        <li><Link to="/" onClick={toggleMenu}>DOMOV</Link></li>
                        <li><Link to="/projects" onClick={toggleMenu}>PROJEKTY</Link></li>
                        <li><Link to="/znalosti" onClick={toggleMenu}>ZNALOSTI</Link></li>
                        <li><Link to="/links" onClick={toggleMenu}>LINKTREE</Link></li>
                        {/* <li><Link to="/cenik" onClick={toggleMenu}>CENÍK</Link></li> */}
                        <li><Link to="/tech" onClick={toggleMenu}>TECH</Link></li>
                        <li><Link to="/blog" onClick={toggleMenu}>BLOG</Link></li>
                        <li><Link to="/navody" onClick={toggleMenu}>NÁVODY</Link></li>
                    </ul>
                    <button className="burger-menu" onClick={toggleMenu}>
                        {menuOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </nav>
                <Routes>
                    <Route path="/" element={<Portfolio />} />
                    {/* <Route path="/vybaveni" element={<Equipment />} /> */}
                    <Route path="/projects" element={<Timeline />} />
                    <Route path="/links" element={<LinkTree links={links} />} />
                    <Route path="/znalosti" element={<Knowledge />} />
                    {/* <Route path="/cenik" element={<Contact />} /> */}
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:slug" element={<BlogPost />} />
                    <Route path="/navody" element={<Navody />} />
                    <Route path="/navody/:slug" element={<NavodyPost />} />
                    {/* Ratings */}
                    <Route path="/ratings/linux" element={<Linux />} />
                    <Route path="/ratings/aliexpress" element={<Aliexpress />} />
                    <Route path="/ratings/aliexpress/tech" element={<AliexTech />} />
                    {/* testing */}
                    <Route path="/playing" element={<Playing />} />
                    <Route path="/tech" element={<Tech />} />
                    {/* errors */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <footer>
                    <div className="social-icons">
                        <a href="https://instagram.com/ondrasekq_/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        <a href="https://linkedin.com/in/ondrejpacovsky/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                        <a href="https://discord.com/users/570547780804804611" target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
                    </div>
                </footer>
            </div>
        </Router>
    );
};

export default App;
